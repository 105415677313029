import React, { ReactNode, JSXElementConstructor } from 'react'
import { HiArrowRight } from 'react-icons/hi'
import { TSize } from '@utils/types'
import { LoaderIcon } from '@noissue-ui-kit/oldBranding/loaderIcon'
import { proxifyWithFallbackValue } from '@components/ContentfulPage/ConfigurableText/ConfigurableText.utils'

const ButtonIcon = {
  HiArrowRight,
}

type IconName = keyof typeof ButtonIcon

interface IButton {
  children: ReactNode
  variant?: 'primary' | 'outline'
  colour?: 'pitaya' | 'starfruit' | 'acai' | 'pale'
  onClick?: () => void
  ariaLabel?: string
  href?: string
  className?: string
  size: TSize
  corners: 'rounded' | 'square'
  iconName?: IconName
  iconSide?: 'left' | 'right'
  loading?: boolean
  Component?: string | JSXElementConstructor<any>
  disabled?: boolean
  target?: 'blank' | 'self'
}

export const Button = ({
  children,
  variant = 'primary',
  colour = 'pitaya',
  size = 's',
  corners = 'rounded',
  onClick,
  ariaLabel,
  href,
  className,
  iconName,
  iconSide = 'right',
  Component = 'button',
  loading = false,
  disabled = false,
  target = null,
}: IButton) => {
  const colourClassMapper = {
    pitaya: {
      primary:
        'bg-pitaya text-white border-pitaya hover:bg-pitaya-120 hover:border-pitaya-120',
      outline:
        'bg-transparent text-pitaya border-pitaya hover:bg-pitaya-120 hover:border-pitaya-120 hover:text-white',
    },
    starfruit: {
      primary:
        'bg-starfruit text-forest border-starfruit hover:bg-starfruit-120 hover:border-starfruit-120',
      outline:
        'bg-transparent text-starfruit border-starfruit hover:bg-starfruit-120 hover:border-starfruit-120 hover:text-white',
    },
    acai: {
      primary:
        'bg-acai text-white border-acai hover:bg-acai-120 hover:border-acai-120',
      outline:
        'bg-transparent text-acai border-acai hover:bg-acai-120 hover:border-acai-120 hover:text-white',
    },
    pale: {
      primary: 'bg-black-10 text-black-80 border-black-10 cursor-default',
      outline: 'bg-transparent text-black-80 border-black-10 cursor-default',
    },
    disabledState: {
      primary:
        'bg-core-grey-30 text-core-grey-60 border-core-grey-40 cursor-default',
      outline:
        'bg-transparent text-core-grey-30 border-core-grey-40 cursor-default',
    },
  }

  const mapColorToClass = proxifyWithFallbackValue({
    initialObject: colourClassMapper,
    fallbackValue: colourClassMapper.acai,
    onFallback: (prop) => {
      switch (prop) {
        case 'rose':
          return colourClassMapper.pitaya
        case 'purple':
          return colourClassMapper.acai
        case 'tree':
          return colourClassMapper.starfruit
      }
    },
  })

  const loaderFillColorClassMapper = {
    pitaya: {
      primary: 'fill-white',
      outline: 'fill-pitaya group-hover:fill-white',
    },
    starfruit: {
      primary: 'fill-white',
      outline: 'fill-starfruit group-hover:fill-white',
    },
    acai: {
      primary: 'fill-white',
      outline: 'fill-acai group-hover:fill-white',
    },
    disabledState: {
      primary: 'fill-core-grey-60',
      outline: 'fill-core-grey-30',
    },
  }

  const sizeClassMapper = {
    xs: 'text-lg px-6 py-3 border',
    s: 'text-lg px-9 py-4 border-2',
    m: 'text-xl px-11 py-5 border-2',
    l: 'text-2xl px-16 py-6 border-2',
  }

  const cornersClassMapper = {
    rounded: 'rounded-full',
    square: 'rounded-md',
  }

  const onFocusClass =
    'focus:outline-none focus-visible:ring-2 focus-visible:ring-pitaya focus-visible:ring-offset-2'
  const transitionClass = 'transition-colors duration-200 ease-in-out'

  const DynamicHeroIcon = iconName && ButtonIcon[iconName]

  return (
    <Component
      aria-label={ariaLabel}
      onClick={onClick}
      href={href}
      target={target}
      className={`font-bold border text-center cursor-pointer inline-block group
                    ${transitionClass} ${onFocusClass}
                    ${
                      disabled
                        ? mapColorToClass.disabledState[variant]
                        : mapColorToClass[colour][variant]
                    }
                    ${sizeClassMapper[size]}
                    ${cornersClassMapper[corners] || cornersClassMapper.rounded}
                    ${className}`}
      disabled={disabled}
    >
      <span className="flex items-center justify-center">
        {loading && iconSide === 'left' && (
          <LoaderIcon
            size="s"
            className="mr-4 -mb-1"
            fillColorClass={
              disabled
                ? loaderFillColorClassMapper.disabledState[variant]
                : loaderFillColorClassMapper[colour][variant]
            }
          />
        )}
        {DynamicHeroIcon && iconSide === 'left' && !loading && (
          <DynamicHeroIcon className="h-6 mr-4 -mb-1" />
        )}
        {children}
        {DynamicHeroIcon && iconSide === 'right' && !loading && (
          <DynamicHeroIcon className="h-6 ml-4 -mb-1" />
        )}
        {loading && iconSide === 'right' && (
          <LoaderIcon
            size="s"
            className={`${children ? 'ml-4' : ''} -mb-1`}
            fillColorClass={
              disabled
                ? loaderFillColorClassMapper.disabledState[variant]
                : loaderFillColorClassMapper[colour][variant]
            }
          />
        )}
      </span>
    </Component>
  )
}
