import React, { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import { twMerge } from 'tailwind-merge'

type supportedMarkdownTypes = 'p' | 'ol' | 'h3' | 'a' | 'ul'

export function ContentfulMarkdown({
  children: markdownText,
  overrideStyles,
  overrideClass,
}: {
  children: string
  overrideClass?: string
  overrideStyles?: Partial<
    Record<supportedMarkdownTypes, (unknown) => ReactElement>
  >
}) {
  return (
    <ReactMarkdown
      includeElementIndex
      components={{
        h1: ({ children }) => (
          <h1
            className={twMerge(
              'mb-2 text-xl leading-8 text-acai',
              overrideClass
            )}
          >
            {children}
          </h1>
        ),
        h2: ({ children }) => (
          <h2
            className={twMerge(
              'mb-2 text-xl leading-8 text-acai',
              overrideClass
            )}
          >
            {children}
          </h2>
        ),
        h3: ({ children }) => (
          <h3
            className={twMerge(
              'mb-2 text-xl leading-8 text-acai',
              overrideClass
            )}
          >
            {children}
          </h3>
        ),
        h4: ({ children }) => (
          <h4
            className={twMerge(
              'mb-2 text-xl leading-8 text-acai',
              overrideClass
            )}
          >
            {children}
          </h4>
        ),
        p: ({ children }) => (
          <p
            className={twMerge(
              'mb-2 text-xl leading-8 text-acai',
              overrideClass
            )}
          >
            {children}
          </p>
        ),
        a: ({ children, href }) => (
          <a
            className={twMerge(
              'text-xl ',
              'text-pitaya',
              overrideClass,
              'pointer-events-auto'
            )}
            href={href}
          >
            {children}
          </a>
        ),
        ol: ({ children }) => (
          <ol
            className={twMerge('mb-2 text-xl text-acai', overrideClass)}
            style={{
              marginLeft: '24px',
              listStyleType: 'decimal',
              listStylePosition: 'outside',
            }}
          >
            {children}
          </ol>
        ),
        ul: ({ children }) => (
          <ul
            className={twMerge('mb-2 text-xl text-acai', overrideClass)}
            style={{
              marginLeft: '24px',
              listStyleType: 'disc',
              listStylePosition: 'outside',
            }}
          >
            {children}
          </ul>
        ),
        ...overrideStyles,
      }}
    >
      {markdownText}
    </ReactMarkdown>
  )
}
